<template>
  <header
    class="mm-header"
    :class="{
      'header--light': isLight,
      'header--dark': !isLight,
      'erp-header': isErp,
      'header--transparent': isTransparent,
      'main--hidden': hideMain,
    }"
  >
    <LazyHeaderGodMode v-if="mpAdminInfoEmail" />
    <div
      class="header"
      :class="{
        'header--light': isLight,
        'header--dark': !isLight,
        'header--search': !hideSearch,
        'header--profile': isProfilePageAndAuth,
      }"
    >
      <div
        v-if="!hideMain"
        class="header__main-wrap"
      >
        <div
          v-if="!isErp"
          class="header__col"
        >
          <LazyLayoutsDefaultHeaderLinks :links="links" />
        </div>
        <div
          v-else-if="!hideCityChangeButton"
          id="basis_location_label"
          class="header__location"
        >
          <SvgIcon src="navigation/location" />
          <span class="location__address">
            <div @click="onOpenBasisSelectModal">
              {{ addressToShow }}
            </div>
          </span>
        </div>
        <div class="header__col logo-col">
          <NuxtLink
            to="/"
            class="logo"
            no-prefetch
          >
            <div
              class="header__text-logo logo--desc-on"
              :class="{
                'logo--light': isLight,
                'logo--dark': !isLight,
              }"
            >
              <div class="logo__main-wrap">
                <div class="logo-wrap">
                  <SvgIcon
                    :src="logoSrc"
                    class="logo__icon"
                  />
                  <span class="logo__main">максмарт</span>
                </div>
                <span class="logo__desc">маркетплейс быстрых закупок</span>
              </div>
            </div>
          </NuxtLink>
        </div>
        <div class="header__col">
          <div
            class="header__menu--mobile-top"
            @click="toggleMobileMenu"
          >
            <SvgIcon src="navigation/menu" />
          </div>
          <div
            v-if="!isProfilePageAndAuth && !isErp && !hideCityChangeButton && isUserLoggedIn"
            id="basis_location_label"
            class="header__location"
            @click="onOpenBasisSelectModal"
          >
            <SvgIcon src="navigation/location" />
            <span>
              {{ addressToShow }}
            </span>
          </div>
          <div class="header__login">
            <div class="header__login">
              <div
                v-if="!hideCityChangeButton"
                class="header__login-divider"
              />
              <template v-if="$slots.user">
                <slot name="user" />
                <SvgIcon src="navigation/lk" />
              </template>
              <template v-else>
                <LoginButton
                  :is-error-page="isErrorPage"
                  class="login__btn"
                />
              </template>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isProfilePageAndAuth && !hideSearch && !isErp"
        class="header__search"
        :class="{
          'header__search--location': showLocationInSearch
        }"
      >
        <LazyLayoutsDefaultCatalogButton
          :categories="categories"
          :is-button-disabled="isButtonDisabled"
          :oci-mode="ociMode"
          @on-mobile-menu-open="onMobileMenuOpen"
          @on-popup-open="onPopupOpen"
          @on-popup-close="onPopupClose"
        />
        <LayoutsDefaultSearch
          :categories="categories"
          :placeholder="searchPlaceholder"
        />
        <LazyLayoutsDefaultSearchIcons
          :show-location-in-search="showLocationInSearch"
          :address="addressToShow"
          :icons="defaultHeaderIcons"
          @click-go-to-basket="$emit('clickGoToBasket')"
        />
      </div>

      <div
        class="header__overlay"
        :class="{ closed: !isMobileMenu }"
      >
        <div
          class="header__menu--mobile"
          :class="{ closed: !isMobileMenu }"
        >
          <div class="menu__header">
            <div class="logo">
              <NuxtLink
                to="/"
                no-prefetch
              >
                <SvgIcon
                  src="images/logo-desc-off"
                  class="logo--desc-off"
                />
              </NuxtLink>
            </div>
            <span
              class="close-icon"
              @click="isMobileMenu = !isMobileMenu"
            >
              <SvgIcon src="navigation/close-24px" />
            </span>
          </div> 
          
          <div
            v-if="!hideCityChangeButton && !isUserLoggedIn"
            class="menu__address"
          >
            <span class="address__title">Ваш адрес доставки</span>
            <span
              class="address__main"
              @click="onOpenBasisSelectModal"
            >{{ addressToShow }}
              <span class="address__action">
                <SvgIcon src="action/pen-edit" />
              </span>
            </span>
          </div>

          <div class="menu__col menu__col--mobile">
            <HeaderLinks
              :links="links"
              @click="isMobileMenu = !isMobileMenu"
            />
          </div>

          <div class="menu__contacts">
            <a
              v-for="(item, key) in info"
              :key="key"
              :href="sanitizeUrl(item.link)"
              class="conatcts__link"
            >
              {{ item.label }}
            </a>
            <button class="btn btn-outlined">
              Свяжитесь с нами
            </button>
          </div>
        </div>
      </div>
    </div>

    <LazyLayoutsDefaultCatalogPopup
      v-if="!hideSearch"
      :categories="categories"
      :oci-mode="ociMode"
    />
    <ClientOnly>
      <MobileMenu
        v-if="!hideMobileMenu"
        :oci-mode="ociMode"
      />
    </ClientOnly>
  </header>
</template>

<script lang="ts" setup>
import HeaderLinks from './HeaderLinks.vue';
import { defaultHeaderLinks, defaultHeaderIcons } from 'constants/layouts/header.const';
import SvgIcon from 'shared/components/SvgIcon.vue';
import ModalManager from 'shared/services/modalManager.service';
import { IModalManager } from 'shared/models/modalManager.model';
import { storeToRefs } from 'pinia';
import { useUserStore } from 'store/user.store';
import BasesService from 'services/basesManager.service';
import PopupManager from 'shared/services/popupManager.service';
import { IPopupManager } from 'shared/models/popupManager.model';
import { PimCatalogService } from 'services/api/pimCatalogApi.service';
import { UserHelper } from 'utils/userHelper.util';
import MobileMenu from 'components/MobileMenu.vue';
import { RouterHelper } from 'shared/utils/routerHelper.util';
import HeaderService from 'services/header.service';
import { ILink } from 'shared/models/link.model';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { useBasis } from 'composables/useBasis';
import LoginButton from 'components/LoginButton.vue';
import { EFetchKey } from 'enums/fetchKey.enum';

const props = withDefaults(
  defineProps<{
    dark?: boolean; // Темная тема
    hideSearch?: boolean; // Скрыть поиск
    hideMobileMenu?: boolean; // Скрыть мобильное меню
    hideCityChangeButton?: boolean; // Скрыть кнопку смены города
    changeColor?: boolean; // Должен ли изменяться цвет
    isCategoriesDisabled?: boolean; // Отключены ли категории
    isErp?: boolean; // Являетя ли Erp
    isErrorPage?: boolean; // Находится ли на странице ошибки
    isTransparent?: boolean; // Должен ли быть прозрачным
    links?: Array<ILink>; // Список ссылок
    hideMain?: boolean; // Скрыть основной контент
    searchPlaceholder?: string; // Плейсхолдер для поискового инпута
    showLocationInSearch?: boolean; // Отображается ли адресс в строке поиска
    locationInSearch?: string; // Адресс, который отображается в строке поиска
    disableLoadCookie?: boolean, // Отключить куки
    ociMode?: boolean; // Режим OCI
    clientId?: number; // id клиента
  }>(),
  {
    dark: false,
    hideSearch: false,
    links: defaultHeaderLinks,
  },
);

defineEmits<{
  (e: 'clickGoToBasket'): void;
}>();

const headerService = new HeaderService();
provide(HeaderService.getServiceName(), headerService);
const modalManager = process.client ? inject<IModalManager>(ModalManager.getServiceName()) : undefined;
const popupManager = process.client ? inject<IPopupManager>(PopupManager.getServiceName()) : undefined;
const userStore = useUserStore();
const { isUserLoggedIn } = storeToRefs(userStore);
const basesManager = inject<BasesService>(BasesService.getServiceName());
const { mpAdminInfoEmail } = storeToRefs(useUserStore());
const route = useRoute();
const { authBasisId, unAuthRegionFiasId } = useBasis();

const { data: categories, refresh: refresCategories } = await useLazyAsyncData(EFetchKey.HeaderCategories, async () => {
  try {
    return (await PimCatalogService.getCategories(
      3,
      unAuthRegionFiasId.value,
      undefined,
      undefined,
      props.ociMode ? undefined : props.ociMode,
      props.ociMode,
      props.ociMode,
      props.clientId,
      authBasisId.value,
    ))?.category?.subcategory || [];
  } catch (error) {
    console.error(error);
    return [];
  }
}, { default: () => [] });

useObserveBases(refresCategories);

const addressToShow = computed(() => {
  if (!isUserLoggedIn.value || UserHelper.isSupplier) {
    if (basesManager?.city) {
      return basesManager?.city?.replaceAll?.('"', '');
    }
    return 'Укажите ваш город';
  }
  if (basesManager?.base) {
    return basesManager.base?.replaceAll?.('"', '');
  }
  return 'Базис доставки';
});

const isLight = computed(() => !props.dark || popupManager?.getIsPopupOpened?.()?.value);

interface IHeaderLink {
  label: string;
  link: string;
}

const logoSrc = computed<EIconPath>(() => isLight.value ? EIconPath.ImagesLogoIcon : EIconPath.ImagesLogoIconInverted);

const isMobileMenu = ref<boolean>(false);
const isButtonDisabled = ref(false);
const info = ref<Array<IHeaderLink>>([
  {
    label: '+7 (800) 500-81-06',
    link: 'tel:+78005008106',
  },
  {
    label: 'info@maksmart.ru',
    link: 'mailto:info@maksmart.ru',
  },
]);

function onMobileMenuOpen() {
  isMobileMenu.value = !isMobileMenu.value;
}

function onOpenBasisSelectModal(): void {
  basesManager?.openSelectModal(modalManager, isUserLoggedIn.value);
}

function toggleMobileMenu() {
  isMobileMenu.value = !isMobileMenu.value;
}

function onPopupOpen() {
  headerService.setCatalogOpened(true);
  headerService.setSearchButtonDropdownOpened(false);
}

function onPopupClose() {
  headerService.setCatalogOpened(false);
}

const isProfilePageAndAuth = computed(() => RouterHelper.includePath('/profile', route) && isUserLoggedIn.value);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-god-mode-header {
  max-width: 1600px;
  margin: 0 auto;
}

.header {
  &__location {
    border: none;
  }

  &--profile {
    margin-top: 0;
    padding-bottom: 0;

    .header__main-wrap {
      padding-bottom: 20px;
      border-bottom: 1px solid $dark-gray;
    }
  }
}

.main--hidden {
  .header {
    padding-top: 40px;

    &__search {
      margin-top: 0;
    }
  }
}

.location__address {
  margin-left: 14px;
}

:deep(.mm-catalog-popup) {
  background-color: $white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & > div {
    width: 1584px;
    overflow: hidden;
  }

  .mm-catalog-popup-inner {
    width: 1602px;
  }

  .mm-catalog-popup-inner__left {
    .mm-catalog-popup-inner__list {
      margin-right: -17px;
    }
  }
}

.erp-header {
  .header__login {
    &::before {
      opacity: 0;
    }
  }
}

@media only screen and (min-width: 1281px) {
  .erp-header {
    padding-top: 18px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1279px) {
  .header__location {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1281px) {
  .erp-header {
    padding-top: 10px;
  }

  :deep(.mm-catalog-popup) {
    .mm-catalog-popup-inner {
      width: 1264px;
    }
  }
}

@media only screen and (max-width: 1599px) {
  :deep(.mm-catalog-popup) {
    & > div {
      flex: 1;
    }

    .mm-catalog-popup-inner {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .header__location {
    width: 100%;
  }

  .logo-col {
    margin-top: 0;
  }
}
</style>
